import React from "react";
import { Card, CardContent, Typography, Grid } from "@mui/material";
import { LiveVehicle } from "../../types";

interface CustomerData {
  chassisNumber: string;
  engineNo: string;
  applicationType: string;
  modelCategory: string;
  productName: string;
  vehicleType: string;
  gvw: string;
  passengerCapacity: number | null;
  wheelBase: string;
  engineType: string;
  brakeType: string;
  emissionStandard: string;
  dealerCode: string;
  dealerContactName: string;
  dealerContactNumber: string;
  fuelType: string;
  adblueTankCapacity: string;
  yearOfManufacturing: number;
}

interface VehicleCardProps {
  vehicle: LiveVehicle;
  customerData: CustomerData | null;
}

const VehicleCard: React.FC<VehicleCardProps> = ({ vehicle, customerData }) => {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          <strong>Vehicle Number:</strong> {vehicle.vehicleNumber || "N/A"}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          <strong>IMEI:</strong> {vehicle.uniqueId || "N/A"}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          <strong>VIN:</strong> {vehicle.chassisNumber || "N/A"}
        </Typography>
        <Typography variant="h6" component="div" sx={{ marginTop: 3 }} gutterBottom>
          Vehicle Details
        </Typography>

        <Grid container spacing={3}>
          {customerData?.engineNo && (
            <Grid item xs={3}>
              <Typography variant="body2">
                <strong>Engine Number:</strong> {customerData.engineNo}
              </Typography>
            </Grid>
          )}
          {customerData?.applicationType && (
            <Grid item xs={3}>
              <Typography variant="body2">
                <strong>Application Type:</strong> {customerData.applicationType}
              </Typography>
            </Grid>
          )}
          {customerData?.modelCategory && (
            <Grid item xs={3}>
              <Typography variant="body2">
                <strong>Model Category:</strong> {customerData.modelCategory}
              </Typography>
            </Grid>
          )}
          {customerData?.productName && (
            <Grid item xs={3}>
              <Typography variant="body2">
                <strong>Product Name:</strong> {customerData.productName}
              </Typography>
            </Grid>
          )}
          {customerData?.vehicleType && (
            <Grid item xs={3}>
              <Typography variant="body2">
                <strong>Vehicle Type:</strong> {customerData.vehicleType}
              </Typography>
            </Grid>
          )}
          {customerData?.gvw && (
            <Grid item xs={3}>
              <Typography variant="body2">
                <strong>GVW:</strong> {parseInt(customerData.gvw) + ' kgs'}
              </Typography>
            </Grid>
          )}
          {customerData?.passengerCapacity !== null && (
            <Grid item xs={3}>
              <Typography variant="body2">
                <strong>Passenger Capacity:</strong>{" "}
                {customerData?.passengerCapacity || "N/A"}
              </Typography>
            </Grid>
          )}
          {customerData?.wheelBase && (
            <Grid item xs={3}>
              <Typography variant="body2">
                <strong>Wheel Base:</strong> {customerData.wheelBase}
              </Typography>
            </Grid>
          )}
          {customerData?.engineType && (
            <Grid item xs={3}>
              <Typography variant="body2">
                <strong>Engine Type:</strong> {customerData.engineType}
              </Typography>
            </Grid>
          )}
          {customerData?.brakeType && (
            <Grid item xs={3}>
              <Typography variant="body2">
                <strong>Brake Type:</strong> {customerData.brakeType}
              </Typography>
            </Grid>
          )}
          {customerData?.emissionStandard && (
            <Grid item xs={3}>
              <Typography variant="body2">
                <strong>Emission Standard:</strong> {customerData.emissionStandard}
              </Typography>
            </Grid>
          )}

          {customerData?.fuelType && (
            <Grid item xs={3}>
              <Typography variant="body2">
                <strong>Fuel Type:</strong> {customerData.fuelType}
              </Typography>
            </Grid>
          )}
          {customerData?.adblueTankCapacity && (
            <Grid item xs={3}>
              <Typography variant="body2">
                <strong>AdBlue Tank Capacity:</strong> {customerData.adblueTankCapacity} L
              </Typography>
            </Grid>
          )}
          {customerData?.yearOfManufacturing && (
            <Grid item xs={3}>
              <Typography variant="body2">
                <strong>Year of Manufacturing:</strong> {customerData.yearOfManufacturing}
              </Typography>
            </Grid>
          )}
          {customerData?.dealerCode && (
            <Grid item xs={3}>
              <Typography variant="body2">
                <strong>Dealer Code:</strong> {customerData.dealerCode}
              </Typography>
            </Grid>
          )}
          {customerData?.dealerContactName && (
            <Grid item xs={3}>
              <Typography variant="body2">
                <strong>Dealer Contact:</strong> {customerData.dealerContactName} (
                {customerData.dealerContactNumber || "N/A"})
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default VehicleCard;
