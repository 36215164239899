import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CircularProgress,
  Box,
  TableSortLabel,
  TextField,
  MenuItem,
  IconButton,
  Chip,
} from "@mui/material";
import { useLazyQuery, gql, useQuery } from "@apollo/client";
import Papa from "papaparse";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DownloadIcon from '@mui/icons-material/Download';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import EmailIcon from '@mui/icons-material/Email';
import CloseIcon from '@mui/icons-material/Close';
import DTCDetailCard from "../../DtcDetail/DtcDetailCard";
import { log } from "console";
import { enqueueSnackbar } from "notistack";
import { AnyCnameRecord } from "dns";

interface DetailDialogProps {
  open: boolean;
  onClose: () => void;
  name: string | null | string[];
  queryType: "DTC" | "WarningLamp" | "HealthStatus" | "TrackingStatus" | "AdblueData" | "DtcPattern" | "None";
}

interface Vehicle {
  vehicleNumber: string;
  chassisNumber: string;
  uniqueId: string;
  address: string;
  odometer: number;
  inducementTimeTorque?: number;
  inducementTimeSpeed?: number;
  faultCodes?: { code: string }[];
}

const GET_WARNING_LAMP_VEHICLES = gql`
  query GetVehicleDataFromWarningLamp($warningLamp: String) {
    getVehicleDataFromWarningLamp(warningLamp: $warningLamp) {
      uniqueId
      vehicleNumber
      chassisNumber
      odometer
      latitude
      longitude
      inducementTimeTorque
      inducementTimeSpeed
      hourmeter
      fuelLevel
      address
      adblueLevel
      healthStatus
      faultCodes {
        code
        description
        faultType
        vehicleType
      }
      salesData {
      dealerCode
      dealerContactName
      dealerContactNumber
    }
    }
  }
`;

const GET_DTC_VEHICLES = gql`
  query GetVehicleDataFromDTC($dtc: String) {
  getVehicleDataFromDTC(dtc: $dtc) {
    uniqueId
    trackingStatus
    address
    vehicleNumber
    vehicleModel
    vehicleType
    chassisNumber
    fuelType
    vehIssues
    lastOnline
    trackingDataAvailable
    obdDataAvailable
    latitude
    longitude
    direction
    gpsSignal
    speed
    fuelLevel
    adblueLevel
    mileage
    batteryVoltage
    engineOilTemperature
    engineCoolantTemperature
    ambientAirTemperature
    engineRpm
    odometer
    hourmeter
    faultCodes {
      code
      description
      faultType
      vehicleType
    }
    healthStatus
    distanceTripA
    distanceTripB
    fuelEfficiencyTripA
    fuelEfficiencyTripB
    inducementTimeTorque
    inducementTimeSpeed
    driverName
    contactNumber
    rfid
    countryCode
    license
    distanceToService
    hoursToService
    isHA
    isHB
    salesData {
      dealerCode
      dealerContactName
      dealerContactNumber
    }
  }
}
`;

const GET_HEALTH_STATUS_VEHICLES = gql`
  query GetVehicleDataFromHealthStatus($healthStatus: String) {
    getVehicleDataFromHealthIssues(healthStatus: $healthStatus) {
      uniqueId
      vehicleNumber
      chassisNumber
      odometer
      latitude
      longitude
      inducementTimeTorque
      inducementTimeSpeed
      hourmeter
      fuelLevel
      address
      adblueLevel
      healthStatus
      faultCodes {
        code
        description
        faultType
        vehicleType
      }
      salesData {
      dealerCode
      dealerContactName
      dealerContactNumber
    }
    }
  }
`;

const GET_TRACKING_STATUS_VEHICLES = gql`
  query GetVehicleFromTrackingStatus($trackingStatus: String) {
    getVehicleFromTrackingStatus(trackingStatus: $trackingStatus) {
      uniqueId
      vehicleNumber
      chassisNumber
      odometer
      latitude
      longitude
      inducementTimeTorque
      inducementTimeSpeed
      hourmeter
      fuelLevel
      address
      adblueLevel
      healthStatus
      faultCodes {
        code
        description
        faultType
        vehicleType
      }
      salesData {
      dealerCode
      dealerContactName
      dealerContactNumber
    }
    }
  }
`;

const GET_ADBLUE_DATA_VEHICLES = gql`
query GetVehiclesFromAdblueCard($adblueCategory: String!) {
  getVehiclesFromAdblueCard(adblueCategory: $adblueCategory) {
      uniqueId
      vehicleNumber
      chassisNumber
      odometer
      latitude
      longitude
      inducementTimeTorque
      inducementTimeSpeed
      hourmeter
      fuelLevel
      address
      adblueLevel
      healthStatus
      faultCodes {
        code
        description
        faultType
        vehicleType
      }
      salesData {
      dealerCode
      dealerContactName
      dealerContactNumber
    }
    }
  }
`;
const SEND_VEHICLE_DETAILS_EMAIL = gql`
  query SendVehicleDetailsEmail($vehicle: VehicleInput!, $recipientEmail: String!) {
    sendVehicleDetailsEmail(vehicle: $vehicle, recipientEmail: $recipientEmail)
  }
`;

const DetailDialog: React.FC<DetailDialogProps> = ({
  open,
  onClose,
  name,
  queryType,
}) => {
  const {
    data: warningLampData,
    loading: warningLampLoading,
    error: warningLampError,
  } = useQuery(GET_WARNING_LAMP_VEHICLES, {
    variables: { warningLamp: name },
    skip: !open || queryType !== "WarningLamp",
  });

  const {
    data: dtcData,
    loading: dtcLoading,
    error: dtcError,
  } = useQuery(GET_DTC_VEHICLES, {
    variables: { dtc: name },
    skip: !open || queryType !== "DTC",
  });

  const {
    data: healthStatusData,
    loading: healthStatusLoading,
    error: healthStatusError,
  } = useQuery(GET_HEALTH_STATUS_VEHICLES, {
    variables: { healthStatus: name },
    skip: !open || queryType !== "HealthStatus",
  });

  const {
    data: trackingStatusData,
    loading: trackingStatusLoading,
    error: trackingStatusError,
  } = useQuery(GET_TRACKING_STATUS_VEHICLES, {
    variables: { trackingStatus: name },
    skip: !open || queryType !== "TrackingStatus",
  });

  const {
    data: adblueData,
    loading: adblueDataLoading,
    error: adblueDataError
  } = useQuery(GET_ADBLUE_DATA_VEHICLES, {
    variables: { adblueCategory: name },
    skip: !open || queryType !== "AdblueData"
  });

  const {
    data: p2ba9Data,
    loading: p2ba9Loading,
    error: p2ba9Error,
  } = useQuery(GET_DTC_VEHICLES, {
    variables: { dtc: "P2BA9" },
    skip: !open || queryType !== "DTC" || name !== "P2BA9",
  });

  const {
    data: p2babData,
    loading: p2babLoading,
    error: p2babError,
  } = useQuery(GET_DTC_VEHICLES, {
    variables: { dtc: "P2BAB" },
    skip: !open || queryType !== "DTC" || name !== "P2BA9",
  });

  const {
    data: p2bacData,
    loading: p2bacLoading,
    error: p2bacError,
  } = useQuery(GET_DTC_VEHICLES, {
    variables: { dtc: "P2BAC" },
    skip: !open || queryType !== "DTC" || name !== "P2BA9",
  });
  const { refetch: sendVehicleDetailsEmail } = useQuery(SEND_VEHICLE_DETAILS_EMAIL, {
    skip: true, // Skip the query on initial render
  });

  const [tableData, setTableData] = useState<any[]>([]);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: "asc" | "desc";
  } | null>(null);
  const [filterConfig, setFilterConfig] = useState<string>("");
  const [isAdBlueQuery, setIsAdblueQuery] = useState<boolean>(false);
  const [hoveredItem, setHoveredItem] = useState<{ faultCode: string, vehicleId: string, x: number, y: number } | null>(null);
  const [emailopen, setEmailOpen] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [emailList, setEmailList] = useState<string[]>([]);
  const [emailVehicle,setEmailVehicle] = useState<Vehicle | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (warningLampData) {
      setTableData(warningLampData.getVehicleDataFromWarningLamp);
    } else if (dtcData && name !== "P2BA9") {
      setTableData(dtcData.getVehicleDataFromDTC);
    } else if (healthStatusData) {
      setTableData(healthStatusData.getVehicleDataFromHealthIssues);
    } else if (trackingStatusData) {
      setTableData(trackingStatusData.getVehicleFromTrackingStatus);
    } else if (adblueData) {
      setTableData(adblueData.getVehiclesFromAdblueCard);
      setIsAdblueQuery(true);
    } else if (name === "P2BA9" && p2ba9Data && p2babData && p2bacData) {
      // Add null checks before accessing getVehicleDataFromDTC
      const p2ba9Vehicles = p2ba9Data?.getVehicleDataFromDTC || [];
      const p2babVehicles = p2babData?.getVehicleDataFromDTC || [];
      const p2bacVehicles = p2bacData?.getVehicleDataFromDTC || [];

      // Combine the vehicle data
      const combinedData = [...p2ba9Vehicles, ...p2babVehicles, ...p2bacVehicles];

      setTableData(combinedData);
      setIsAdblueQuery(true);
    }
  }, [
    warningLampData,
    dtcData,
    healthStatusData,
    trackingStatusData,
    adblueData,
    name,
    p2ba9Data,
    p2babData,
    p2bacData,
  ]);

  const handleRowClick = (uniqueId: string) => {
    navigate(`/vehicle-diagnostics?uniqueId=${uniqueId}`);
  };

  const handleClose = () => {
    setTableData([]); // Clear the table data
    onClose(); // Call the passed in onClose function
  };

  const handleLatLngClick = (uniqueId: string) => {
    window.open(
      `https://pro.smlsaarthi.com/tracking/vehicle-details/${uniqueId}`,
      "_blank"
    );
  };

  const formatMinutesToHoursMinutes = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    return `${hours}h ${mins}m`;
  };

  const handleSort = (key: string) => {
    let direction: "asc" | "desc" = "asc";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const sortedData = React.useMemo(() => {
    if (!sortConfig) return tableData;

    const sortedArray = [...tableData].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });

    return sortedArray;
  }, [tableData, sortConfig]);

  const filteredData = React.useMemo(() => {
    if (!filterConfig) return sortedData;

    return sortedData.filter(
      (vehicle) => vehicle.healthStatus === filterConfig
    );
  }, [sortedData, filterConfig]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterConfig(event.target.value);
  };


  const handleFilterClick = (faultCodes: any[]) => {
    const dtcPattern = faultCodes.map((fault) => fault.code);
    // Serialize the faultCodes array into a query string
    const queryParams = new URLSearchParams({ faultCodes: JSON.stringify(dtcPattern) }).toString();

    // Open the DtcPattern page with the faultCodes passed as query parameters
    window.open(`/dtcpattern?${queryParams}`, "_blank");

  };

  const handleFaultCodeClick = (faultCode: string) => {
    const queryParams = new URLSearchParams({ faultCode: faultCode });

    window.open(`/dtcdetail?${queryParams}`, "_blank");
  };

  const handleVehicleClick = (uniqueId: string) => {
    const queryParams = new URLSearchParams({ uniqueId: uniqueId });

    window.open(`/dtcdetail?${queryParams}`, "_blank");
  }


  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>, faultCode: string, vehicleId: string) => {
    const { clientX, clientY } = event;
    setHoveredItem({ faultCode, vehicleId, x: clientX, y: clientY });
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const renderDTCcard = (faultCode: string) => {
    if (!hoveredItem || hoveredItem.x === undefined || hoveredItem.y === undefined) {
      return null;
    }

    return (
      <div
        style={{
          position: 'fixed',
          top: hoveredItem.y,
          left: hoveredItem.x - 250, // Adjust this value to control the distance from the cursor
          zIndex: 20,
          backgroundColor: 'white',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
          padding: '10px',
        }}
      >
        <DTCDetailCard faultCode={faultCode} />
      </div>
    );
  };

  // Handle opening the dialog
  const handleEmailClick = (rowData: any) => {
    setEmailOpen(true);
    setEmailVehicle(rowData);
  };

  // Handle closing the dialog
  const handleEmailClose = () => {
    setEmailOpen(false);
    setEmailInput("");
    setEmailList([]);
    setEmailVehicle(null)
  };

  // Handle adding email to list
  const handleAddEmail = () => {
    if (emailInput && validateEmail(emailInput)) {
      setEmailList([...emailList, emailInput]);
      setEmailInput(""); // clear the input
    } else {
      enqueueSnackbar("Please enter a valid email", { variant: "warning" });
    }
  };

  // Validate email format
  const validateEmail = (email: string) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  // Handle sending email
  const handleSendEmail = async () => {
    if (!emailVehicle) return; // Ensure emailVehicle is not null

    const vehicleData = {
      vehicleNo: emailVehicle.vehicleNumber,
      chassisNo: emailVehicle.chassisNumber,
      imeiNO: emailVehicle.uniqueId,
      currentLoaction: emailVehicle.address,
      odometer: emailVehicle.odometer,
      inducementLevel1: emailVehicle.inducementTimeTorque
        ? formatMinutesToHoursMinutes(emailVehicle.inducementTimeTorque)
        : "NA",
      inducementLevel2: emailVehicle.inducementTimeSpeed
        ? formatMinutesToHoursMinutes(emailVehicle.inducementTimeSpeed)
        : "NA",
      faultCode: emailVehicle.faultCodes
        ? emailVehicle.faultCodes
            .filter((fault: any) => fault.code !== name)
            .map((fault: any) => fault.code)
            .join(", ")
        : "NA",
    };

    try {
      const { data } = await sendVehicleDetailsEmail({
        vehicle: vehicleData,
        recipientEmail: emailList.join(", "), // send to all emails
      });
      enqueueSnackbar("Email Sent successfully", { variant: "success" });
      handleEmailClose(); // close the dialog after successful email
    } catch (error) {
      enqueueSnackbar("Something went wrong", { variant: "error" });
      console.error("Error sending email:", error);
    }
  };


  const handleDownloadCSV = () => {
    if (filteredData.length === 0) return;

    const csvData = filteredData.map((vehicle) => ({
      "IMEI No": vehicle.uniqueId || "NA",
      "Vehicle No": vehicle.vehicleNumber || "NA",
      "Chassis No": vehicle.chassisNumber || "NA",

      "Level 1 Inducement": vehicle.inducementTimeTorque
        ? formatMinutesToHoursMinutes(vehicle.inducementTimeTorque)
        : "NA",
      "Level 3 Inducement": vehicle.inducementTimeSpeed
        ? formatMinutesToHoursMinutes(vehicle.inducementTimeSpeed)
        : "NA",
      Odometer: vehicle.odometer ? vehicle.odometer + " kms" : "NA",
      "Current Location": `${vehicle.latitude || "NA"}, ${vehicle.longitude || "NA"
        }`,
      Hourmeter: vehicle.hourmeter ? vehicle.hourmeter + " hrs" : "NA",
      "Health Status": vehicle.healthStatus || "NA",
      "Fault Codes": vehicle.faultCodes
        ? vehicle.faultCodes
          .filter((fault: any) => fault.code !== name)
          .map((fault: any) => fault.code)
          .join(", ")
        : "NA",
    }));

    const csv = Papa.unparse(csvData, {
      header: true,
      skipEmptyLines: true,
    });

    const filename = `${name}_data.csv`;

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
    saveAs(blob, filename);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
        <DialogTitle>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            Summary
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* Filter dropdown moved to the right */}
              <TextField
                select
                label="Filter by Health Status"
                value={filterConfig}
                name="filterKey"
                onChange={handleFilterChange}
                sx={{ width: "200px", marginRight: 2 }}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="GOOD">Good</MenuItem>
                <MenuItem value="MINOR_ISSUE">Minor</MenuItem>
                <MenuItem value="MAJOR_ISSUE">Major</MenuItem>
              </TextField>

              {/* Download icon button */}
              <IconButton onClick={handleDownloadCSV} color="primary">
                <DownloadIcon />
              </IconButton>
              <IconButton onClick={handleClose} color="primary">
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Typography variant="body1">
            {name ? `Details for ${name}` : "No name selected"}
          </Typography>
          {warningLampLoading ||
            dtcLoading ||
            healthStatusLoading ||
            trackingStatusLoading ||
            adblueDataLoading ||
            p2ba9Loading ||
            p2babLoading ||
            p2bacLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
              }}
            >
              <CircularProgress />
            </Box>
          ) : warningLampError ||
            dtcError ||
            healthStatusError ||
            trackingStatusError ||
            adblueDataError ||
            p2ba9Error ||
            p2babError ||
            p2bacError ? (
            <Typography variant="body1">Error loading details</Typography>
          ) : filteredData !== null ? (
            <Box sx={{ marginTop: 2 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow >
                    {/* New Serial No column */}
                    <TableCell
                      sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                        backgroundColor: "white",
                        borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
                      }}
                      align="center"
                    >
                      <TableSortLabel
                        active={sortConfig?.key === "serialNo"}
                        direction={sortConfig?.key === "serialNo" ? sortConfig.direction : "asc"}
                        onClick={() => handleSort("serialNo")}
                      >
                        Serial No
                      </TableSortLabel>
                    </TableCell>
                    {/* Other columns */}
                    <TableCell
                      sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                        backgroundColor: "white",
                        borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      <TableSortLabel
                        active={sortConfig?.key === "uniqueId"}
                        direction={sortConfig?.key === "uniqueId" ? sortConfig.direction : "asc"}
                        onClick={() => handleSort("uniqueId")}
                      >
                        IMEI No
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                        backgroundColor: "white",
                        borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      <TableSortLabel
                        active={sortConfig?.key === "vehicleNumber"}
                        direction={sortConfig?.key === "vehicleNumber" ? sortConfig.direction : "asc"}
                        onClick={() => handleSort("vehicleNumber")}
                      >
                        Vehicle No <br /> Chassis No
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                        backgroundColor: "white",
                        borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      Current Location
                    </TableCell>
                    {isAdBlueQuery && (
                      <TableCell
                        sx={{
                          position: "sticky",
                          top: 0,
                          zIndex: 2,
                          backgroundColor: "white",
                          borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
                        }}
                      >
                        <TableSortLabel
                          active={sortConfig?.key === "adblueLevel"}
                          direction={sortConfig?.key === "adblueLevel" ? sortConfig.direction : "asc"}
                          onClick={() => handleSort("adblueLevel")}
                        >
                          AdBlue Level
                        </TableSortLabel>
                      </TableCell>

                    )}
                    {isAdBlueQuery && (
                      <TableCell
                        sx={{
                          position: "sticky",
                          top: 0,
                          zIndex: 2,
                          backgroundColor: "white",
                          borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
                        }}
                      >
                        <TableSortLabel
                          active={sortConfig?.key === "adblueLevel"}
                          direction={sortConfig?.key === "adblueLevel" ? sortConfig.direction : "asc"}
                          onClick={() => handleSort("adblueLevel")}
                        >
                          AdBlue Concentration
                        </TableSortLabel>
                      </TableCell>

                    )}
                    <TableCell
                      sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: 2,
                        backgroundColor: "white",
                        borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      <TableSortLabel
                        active={sortConfig?.key === "inducementTimeTorque"}
                        direction={sortConfig?.key === "inducementTimeTorque" ? sortConfig.direction : "asc"}
                        onClick={() => handleSort("inducementTimeTorque")}
                      >
                        Level 1 Inducement
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 2,
                        backgroundColor: 'white',
                        borderBottom: '2px solid rgba(0, 0, 0, 0.87)',
                      }}
                    >
                      <TableSortLabel
                        active={sortConfig?.key === "inducementTimeSpeed"}
                        direction={sortConfig?.key === "inducementTimeSpeed" ? sortConfig.direction : "asc"}
                        onClick={() => handleSort("inducementTimeSpeed")}
                      >
                        Level 3 Inducement
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      <TableSortLabel
                        active={sortConfig?.key === "odometer"}
                        direction={sortConfig?.key === "odometer" ? sortConfig.direction : "asc"}
                        onClick={() => handleSort("odometer")}
                      >
                        Odometer <br /> Hourmeter
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 2,
                        backgroundColor: 'white',
                        borderBottom: '2px solid rgba(0, 0, 0, 0.87)',
                      }}
                    >
                      <TableSortLabel
                        active={sortConfig?.key === "healthStatus"}
                        direction={sortConfig?.key === "healthStatus" ? sortConfig.direction : "asc"}
                        onClick={() => handleSort("healthStatus")}
                      >
                        Health Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      Dealer Code<br />Dealer Name <br /> Dealer Contact
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: "2px solid rgba(0, 0, 0, 0.87)",
                      }}
                    >
                      Fault Codes
                    </TableCell>
                    {/* New Filter column */}
                    <TableCell
                      sx={{
                        position: 'sticky',
                        top: 0,
                        zIndex: 2,
                        backgroundColor: 'white',
                        borderBottom: '2px solid rgba(0, 0, 0, 0.87)',
                      }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((vehicle: any, index: number) => {
                    const faultCodes = Array.isArray(vehicle.faultCodes) ? vehicle.faultCodes : [];
                    const selectedFaultCode = faultCodes.filter((fault: any) => fault.code === name) || [];
                    const otherFaultCodes = faultCodes.filter((fault: any) => fault.code !== name) || [];

                    return (
                      <TableRow key={index}>
                        {/* New Serial No cell */}
                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                          }}
                          align="center"
                        >
                          {index + 1}
                        </TableCell>
                        {/* Existing cells */}
                        <TableCell
                          style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                          sx={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                          }}
                          onClick={() => handleRowClick(vehicle.uniqueId)}
                        >
                          {vehicle.uniqueId || "NA"}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                          }}
                        >
                          {vehicle.vehicleNumber || "NA"}
                          <br />
                          {vehicle.chassisNumber || "NA"}
                        </TableCell>
                        <TableCell
                          style={{ color: "blue", textDecoration: "underline", cursor: "pointer" }}
                          sx={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                          }}
                          onClick={() => handleLatLngClick(vehicle.uniqueId)}
                        >
                          {vehicle.address}
                        </TableCell>
                        {isAdBlueQuery && (
                          <TableCell
                            sx={{
                              borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                            }}
                          >
                            {vehicle.adblueLevel ? `${vehicle.adblueLevel.toFixed(2)}%` : "NA"}
                          </TableCell>
                        )}
                        {isAdBlueQuery && (
                          <TableCell
                            sx={{
                              borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                            }}
                          >
                            {vehicle.ureaQuality ? `${vehicle.ureaQuality.toFixed(2)}%` : "NA"}
                          </TableCell>
                        )}
                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                          }}
                        >
                          {vehicle.inducementTimeTorque
                            ? formatMinutesToHoursMinutes(vehicle.inducementTimeTorque)
                            : "NA"}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                          }}
                        >
                          {vehicle.inducementTimeSpeed
                            ? formatMinutesToHoursMinutes(vehicle.inducementTimeSpeed)
                            : "NA"}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                          }}
                        >
                          {vehicle.odometer ? vehicle.odometer + " kms" : "NA"}
                          <br /> {vehicle.hourmeter ? vehicle.hourmeter + " hrs" : "NA"}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                          }}
                        >
                          {vehicle.healthStatus || "NA"}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                          }}
                        >
                          {vehicle.salesData?.dealerCode || "NA"}
                          <br />
                          {vehicle.salesData?.dealerContactName || "NA"}
                          <br />
                          {vehicle.salesData?.dealerContactNumber || "NA"}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                            position: 'relative', // Ensure the DTC card is positioned correctly
                          }}
                        >
                          {selectedFaultCode.length > 0 && (
                            <div
                              onMouseEnter={(mouseEvent) => handleMouseEnter(mouseEvent, selectedFaultCode[0].code, vehicle.uniqueId)}
                              onMouseLeave={handleMouseLeave}
                              style={{ color: "red" }}
                            >
                              {selectedFaultCode[0].code}
                              {hoveredItem?.faultCode === selectedFaultCode[0].code && hoveredItem?.vehicleId === vehicle.uniqueId && renderDTCcard(selectedFaultCode[0].code)}
                            </div>
                          )}

                          {otherFaultCodes.length > 0 &&
                            otherFaultCodes.map((fault: any, idx: number) => (
                              <div
                                key={idx}
                                onMouseEnter={(mouseEvent) => handleMouseEnter(mouseEvent, fault.code, vehicle.uniqueId)}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  position: 'relative',
                                  color: fault.code.match(/P2BA9|P2BAB|P2BAC/) ? "red" : "inherit"
                                }}
                              >
                                {fault.code}
                                {hoveredItem?.faultCode === fault.code && hoveredItem?.vehicleId === vehicle.uniqueId && renderDTCcard(fault.code)}
                              </div>
                            ))
                          }
                          {faultCodes.length === 0 && "NA"}
                        </TableCell>
                        {/* Filter column */}
                        <TableCell
                          sx={{
                            borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
                            position: 'relative',
                          }}
                        >
                          <FilterAltIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={() => handleFilterClick(faultCodes)}
                          />
                          <ReportProblemIcon sx={{ cursor: 'pointer' }} onClick={() => handleVehicleClick(vehicle.uniqueId)} />
                          <EmailIcon sx={{ cursor: 'pointer' }} onClick={() => handleEmailClick(vehicle)} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>

          ) : (
            <Typography variant="body1">
              No Data Available for the {name}
            </Typography>
          )}
        </DialogTitle>
      </Dialog>
      <Dialog open={emailopen} onClose={handleEmailClose}>
        <DialogTitle>Send Email</DialogTitle>
        <DialogContent>
          <TextField
            label="Enter Email"
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
            fullWidth
          />
          <Button onClick={handleAddEmail} variant="contained" sx={{ mt: 2 }}>
            Add Email
          </Button>

          {/* Display added email chips */}
          <div style={{ marginTop: 10 }}>
            {emailList.map((email, index) => (
              <Chip
                key={index}
                label={email}
                onDelete={() => setEmailList(emailList.filter((_, i) => i !== index))}
                style={{ marginRight: 5, marginTop: 5 }}
              />
            ))}
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleEmailClose}>Cancel</Button>
          <Button onClick={handleSendEmail} variant="contained" color="primary">
            Send Email
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DetailDialog;
