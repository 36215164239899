import React, { useEffect, useState } from "react";
import Running from "../../assets/smlIcons/Dashboard Icons/Running.svg";
import Stopped from "../../assets/smlIcons/Dashboard Icons/Stopped.svg";
import NoGPS from "../../assets/smlIcons/Dashboard Icons/No GPS.svg";
import Offline from "../../assets/smlIcons/Dashboard Icons/Offline.svg";
import Idle from "../../assets/smlIcons/Dashboard Icons/Idling.svg";
import NoData from "../../assets/smlIcons/Dashboard Icons/No Data.svg";
import Good from "../../assets/smlIcons/PerformanceIcons/Good.svg";
import MinorIssue from "../../assets/smlIcons/PerformanceIcons/Minor.svg";
import MajorIssue from "../../assets/smlIcons/PerformanceIcons/Major.svg";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import dayjs from "dayjs";
import TimePeriodSelector from "../../components/Miscellaneous/TimePeriodSelector";
import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { DPFParams, IUMPRData, LiveVehicle, SoftwareVersions, VehicleRecord } from "../../types";
import GearPercentageTable from "../../components/VehiclePerformance/GearTable";
import FuelAdblueTable from "../../components/VehiclePerformance/FuelandAdblue";
import ACUsageTable from "../../components/VehiclePerformance/ACUsage";
import DriveModeTable from "../../components/VehiclePerformance/DriveModeTable";
import SoftwareVersionTable from "../../components/VehiclePerformance/SoftwareVersionTable";
import IUMPRTable from "../../components/VehiclePerformance/IUMPRTable";
import DPFParamsTable from "../../components/VehiclePerformance/DPFParamsTable"; // Import DPFParamsTable
import theme from "../../theme";
import CountCard from "../../components/dashboard/VehicleCountComponents/CountCard";
import { useNavigate } from "react-router-dom";
import VehicleCard from "../../components/VehicleDiagnostics/VehicleInfoCard";

const GET_VEHICLE_PERFORMANCE = gql`
  query GetVehicleHistoryData($uniqueId: String!, $fromTs: Int!, $toTs: Int!) {
    getVehicleHistoryData(uniqueId: $uniqueId, fromTs: $fromTs, toTs: $toTs) {
      date
      gear_data
      ac_usage
      vehicle_running_mode
      total_obd_distance
      total_engine_hour
      total_fuel_consumed
      mileage
      total_adblue_consumed
      avg_speed
      dtc_codes
      lamps
      ems_ecu_software_version
      meter_set_software_version
      firmwareversion
      soot_mass
      number_of_regeneration_demands
      number_of_successful_regenerations
      distance_in_kms_for_last_regeneration
      obd_monitoring_conditions_encountered
      ignition_cycle_counter
      nmhc_catalyst_monitor_completion
      nmhc_catalyst_monitor_conditions_encountered
      nox_catalyst_monitor_completion
      nox_catalyst_monitor_conditions_encountered
      nox_adsorber_monitor_completion
      nox_adsorber_monitor_conditions_encountered
      pm_filter_monitor_completion
      pm_filter_monitor_conditions_encountered
      exhaust_gas_sensor_monitor_completion
      exhaust_gas_sensor_monitor_conditions_encountered
      egr_value_vvt_monitor_completion
      egr_value_vvt_monitor_conditions_encountered
      boost_pressure_monitor_completion
      boost_pressure_monitor_conditions_encountered
      fuel_monitor_completion
      fuel_monitor_conditions_encountered
    }
  }
`;

const GET_ALL_VEHICLE_LIVE_STATUS = gql`
  query GetAllVehicleLiveStatus {
    getAllVehicleLiveStatus {
      uniqueId
      vehicleNumber
      chassisNumber
    }
  }
`;

const GET_VEHICLE_LIVE_DATA = gql`
  query GetVehicleLiveData($uniqueId: String!) {
    getVehicleLiveData(uniqueId: $uniqueId) {
      date
      gear_data
      ac_usage
      trackingStatus
      healthStatus
      vehicle_running_mode
      total_obd_distance
      total_engine_hour
      total_fuel_consumed
      mileage
      total_adblue_consumed
      avg_speed
      dtc_codes
      lamps
      ems_ecu_software_version
      meter_set_software_version
      firmwareversion
      soot_mass
      number_of_regeneration_demands
      number_of_successful_regenerations
      distance_in_kms_for_last_regeneration
      obd_monitoring_conditions_encountered
      ignition_cycle_counter
      nmhc_catalyst_monitor_completion
      nmhc_catalyst_monitor_conditions_encountered
      nox_catalyst_monitor_completion
      nox_catalyst_monitor_conditions_encountered
      nox_adsorber_monitor_completion
      nox_adsorber_monitor_conditions_encountered
      pm_filter_monitor_completion
      pm_filter_monitor_conditions_encountered
      exhaust_gas_sensor_monitor_completion
      exhaust_gas_sensor_monitor_conditions_encountered
      egr_value_vvt_monitor_completion
      egr_value_vvt_monitor_conditions_encountered
      boost_pressure_monitor_completion
      boost_pressure_monitor_conditions_encountered
      fuel_monitor_completion
      fuel_monitor_conditions_encountered
    }
  }
`;

 const GET_VEHICLE_SALES_DATA = gql`query GetSalesData($vin: [String!]) {
  getSalesData(vin: $vin) {
    chasisNo
    engineNo
    applicationType
    modelCategory
    productName
    vehicleType
    gvw
    passengerCapacity
    wheelBase
    engineType
    brakeType
    emissionStandard
    dealerCode
    dealerContactName
    dealerContactNumber
    fuelType
    adblueTankCapacity
    yearOfManufacturing
  }
}`;


type TrackingStatus = "RUNNING" | "IDLE" | "HALT" | "NO_GPS" | "NODATA" | "OFFLINE";
type HealthStatus = "GOOD" | "MINOR_ISSUE" | "MAJOR_ISSUE";


const VehiclePerformance = () => {
  const navigate = useNavigate();
  const [allVehicleLiveStatus, setAllVehicleLiveStatus] = useState<
    LiveVehicle[]
  >([]);
  const [selectedVehicle, setSelectedVehicle] = useState<LiveVehicle | null>(
    null
  );
  const [fromTime, setFromTime] = useState<number>(
    Math.floor(new Date().setHours(0, 0, 0, 0) / 1000)
  );
  const [option, setOption] = useState<string>("WEEK");
  const [toTime, setToTime] = useState<number>(Math.floor(Date.now() / 1000));
  const [isDefaultSelected, setIsDefaultSelected] = useState(true);
  const [vehiclePerformanceData, setVehiclePerformanceData] =
    useState<VehicleRecord | null>(null);
  const [livePerformanceData, setLivePerformanceData] =
    useState<VehicleRecord | null>(null);
    const [customerData, setCustomerData] = useState<any>();

  const [getAllVehicleLiveStatus] = useLazyQuery(GET_ALL_VEHICLE_LIVE_STATUS, {
    onCompleted: (data: { getAllVehicleLiveStatus: LiveVehicle[] }) => {
      setAllVehicleLiveStatus(data.getAllVehicleLiveStatus);
    },
  });
  const [getSalesData, { data: salesData, loading: salesLoading, error: salesError }] = useLazyQuery(GET_VEHICLE_SALES_DATA);

  const [
    getVehicleLiveData,
    {
      loading: vehicleLiveDataLoading,
      error: vehicleLiveDataError,
      data: vehicleLiveDataResult,
    },
  ] = useLazyQuery(GET_VEHICLE_LIVE_DATA, {
    onCompleted: (data) => {
      setLivePerformanceData(data.getVehicleLiveData);
    },
  });

  useEffect(() => {
    getAllVehicleLiveStatus();
  }, [getAllVehicleLiveStatus]);

  useEffect(() => {
    if (selectedVehicle) {
      getVehicleLiveData({ variables: { uniqueId: selectedVehicle.uniqueId } });
    }
  }, [selectedVehicle, getVehicleLiveData]);

  const [getVehiclePerformanceData] = useLazyQuery(GET_VEHICLE_PERFORMANCE, {
    onCompleted: (data) => {
      setVehiclePerformanceData(data.getVehicleHistoryData);
    },
  });

  const {
    startPolling,
    stopPolling,
    data: liveData,
  } = useQuery(GET_VEHICLE_LIVE_DATA, {
    variables: { uniqueId: selectedVehicle?.uniqueId },
    skip: (!selectedVehicle && !vehiclePerformanceData) || vehiclePerformanceData === null,
    pollInterval: 10000, // Poll every 10 seconds
    onCompleted: (data) => {
      if (data && data.getVehicleLiveData) {
        setLivePerformanceData(data.getVehicleLiveData);
      }
    },
  });

  // useEffect(() => {
  //   if (liveData && liveData.getVehicleLiveData) {
  //     setLivePerformanceData(liveData.getVehicleLiveData);
  //   }
  // }, [liveData, selectedVehicle, vehiclePerformanceData]);

  useEffect(() => {
    setIsDefaultSelected(option === "CUSTOM" || option === "");
    if (option !== "CUSTOM" && option !== "") {
      const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
      switch (option) {
        case "HOUR":
          setFromTime(currentTimeInSeconds - 60 * 60); // Last 1 hour
          break;
        case "DAY":
          setFromTime(currentTimeInSeconds - 24 * 60 * 60); // Last 1 day
          break;
        case "WEEK":
          setFromTime(currentTimeInSeconds - 7 * 24 * 60 * 60); // Last 7 days
          break;
        case "MONTH":
          setFromTime(currentTimeInSeconds - 30 * 24 * 60 * 60); // Last 30 days
          break;
        default:
          break;
      }
    }
  }, [option]);

  const handleOptionChange = (e: React.ChangeEvent<{ value: string }>) => {
    e.preventDefault();
    setOption(e.target.value);
  };

  const handleSubmit = () => {
    if (fromTime && fromTime >= toTime) {
      enqueueSnackbar("Date range provided is wrong");
    } else if (
      (fromTime && fromTime > dayjs().unix()) ||
      toTime > dayjs().unix()
    ) {
      enqueueSnackbar("Future dates are not allowed");
    } else {
      setFromTime(fromTime);
      setToTime(toTime);
      if (selectedVehicle) {
        getVehiclePerformanceData({
          variables: {
            uniqueId: selectedVehicle.uniqueId,
            fromTs: fromTime,
            toTs: toTime,
          },
        });
        if (selectedVehicle.chassisNumber) {
          getSalesData({ variables: { vin: [selectedVehicle.chassisNumber] } });
        }
      }
    }
  };

  useEffect(() => {
    if (salesData && salesData.getSalesData) {
      setCustomerData(salesData.getSalesData[0]); // Assuming getSalesData returns an array
    }
  }, [salesData]);

  const handleSelectVehicle = (
    event: React.ChangeEvent<{}>,
    newValue: LiveVehicle
  ) => {
    setSelectedVehicle(newValue);
  };

  const trackingStatusColors = {
    RUNNING: theme.palette.lightgreen.main,
    IDLE: theme.palette.lightyellow.main,
    HALT: theme.palette.lightred.main,
    NO_GPS: theme.palette.grey2.main,
    NODATA: theme.palette.grey2.main,
    OFFLINE: theme.palette.grey2.main,
  };

  const healthStatusColors = {
    GOOD: "#C6EFCE",
    MINOR_ISSUE: "#FFEB9C",
    MAJOR_ISSUE: "#FFC7CE",
  };

  const gearLabels: { [key: string]: string } = {
    "0": "Neutral",
    "1": "First",
    "2": "Second",
    "3": "Third",
    "4": "Fourth",
    "5": "Fifth",
    "6": "Sixth",
    "-1": "Reverse",
    // Add other mappings if needed
  };

  const extractIUMPRData = (data: VehicleRecord): IUMPRData => {
    return {
      obd_monitoring_conditions_encountered:
        data.obd_monitoring_conditions_encountered,
      ignition_cycle_counter: data.ignition_cycle_counter,
      nmhc_catalyst_monitor_completion_counts:
        data.nmhc_catalyst_monitor_completion,
      nmhc_catalyst_monitor_conditions_encountered:
        data.nmhc_catalyst_monitor_conditions_encountered,
      nox_catalyst_monitor_completion_counts:
        data.nox_catalyst_monitor_completion,
      nox_catalyst_monitor_conditions_encountered_counts:
        data.nox_catalyst_monitor_conditions_encountered,
      nox_adsorber_monitor_completion_counts:
        data.nox_adsorber_monitor_completion,
      nox_adsorber_monitor_conditions_encountered:
        data.nox_adsorber_monitor_conditions_encountered,
      pm_filter_monitor_completion_counts: data.pm_filter_monitor_completion,
      pm_filter_monitor_conditions_encountered_counts:
        data.pm_filter_monitor_conditions_encountered,
      exhaust_gas_sensor_monitor_completion_counts:
        data.exhaust_gas_sensor_monitor_completion,
      exhaust_gas_sensor_conditions_encountered_counts:
        data.exhaust_gas_sensor_monitor_conditions_encountered,
      egr_vvt_monitor_completion_counts: data.egr_value_vvt_monitor_completion,
      egr_vvt_conditions_encountered_counts:
        data.egr_value_vvt_monitor_conditions_encountered,
      boost_pressure_monitor_completion_counts:
        data.boost_pressure_monitor_completion,
      boost_pressure_monitor_conditions_encountered_counts:
        data.boost_pressure_monitor_conditions_encountered,
      fuel_monitor_completion_counts: data.fuel_monitor_completion,
      fuel_monitor_conditions_encountered_counts:
        data.fuel_monitor_conditions_encountered,
    };
  };

  const extractDPFParams = (data: VehicleRecord): DPFParams => {
    return {
      sootMass: data.soot_mass,
      regenerationDemands: data.number_of_regeneration_demands,
      successfulRegenerations: data.number_of_successful_regenerations,
      lastSuccessfulRegeneration: data.distance_in_kms_for_last_regeneration,
      odometer: data.total_obd_distance,
      timestamp: data.date,
    };
  };

  const extractSoftwareData = (data: VehicleRecord): SoftwareVersions => {
    return {
      firmwareVersion: data.firmwareversion ?? 'NA',
      emsEcuSoftwareVersion: data.ems_ecu_software_version ?? 'NA',
      meterSetSoftwareVersion: data.meter_set_software_version ?? 'NA'
    }
  }

  const getTrackingColor = (status: string): string => {
    return trackingStatusColors[status as TrackingStatus] || theme.palette.grey4.main;
  };

  const getHealthColor = (status: string): string => {
    return healthStatusColors[status as HealthStatus] || theme.palette.grey4.main;
  };

  const handleLatLngClick = (uniqueId: string) => {
    window.open(
      `https://pro.smlsaarthi.com/tracking/vehicle-details/${uniqueId}`,
      "_blank"
    );
  };

  const trackingStatusIcon = {
    RUNNING: Running,
    IDLE: Idle,
    HALT: Stopped,
    NO_GPS: NoGPS,
    NODATA: NoData,
    OFFLINE: Offline,
  };

  const healthStatusIcon = {
    GOOD: Good,
    MINOR_ISSUE: MinorIssue,
    MAJOR_ISSUE: MajorIssue,
  }

  const prepareAcUsageData = (acUsage: any) => {
    return {
      ON: acUsage?.ON || 0,
      OFF: acUsage?.OFF || 0,
    };
  };

  const prepareDriveModeUsageData = (driveMode: any) => {
    return {
      ECO_MODE: driveMode.ECO_MODE || 0,
      POWER_MODE: driveMode.POWER_MODE || 0
    }
  }


  return (
    <>
      <Box
        justifyContent="flex-start"
        sx={{ backgroundColor: "#F6F5F4", padding: 2 }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="flex-start"
          sx={{ padding: 1 }}
        >
          <Grid item xs={4}>
            <Typography
              sx={{ fontSize: 32, fontWeight: "semi-bold", textAlign: "left" }}
            >
              Vehicle Performance Analytics
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TimePeriodSelector
              option={option}
              setFromTs={setFromTime}
              fromTs={fromTime}
              toTs={toTime}
              setToTs={setToTime}
              onOptionChange={handleOptionChange}
              handleSubmit={handleSubmit}
              handleSelectVehicle={handleSelectVehicle}
              vehicles={allVehicleLiveStatus}
              isDefaultSelected={isDefaultSelected}
              selectedVehicle={selectedVehicle}
              isDashBoard={false}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                {livePerformanceData && (
                  <Typography variant="caption" color="textSecondary">
                    Live data updated at: {new Date().toLocaleTimeString()}
                  </Typography>
                )}
              </Grid>
              <Grid container spacing={1}>
              {customerData && selectedVehicle && (
                <Grid item xs={12} md={12}>
                  <VehicleCard vehicle={selectedVehicle} customerData={customerData} />
                </Grid>)}
                <Grid item xs={4}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Box>
                        {selectedVehicle && vehiclePerformanceData && livePerformanceData && customerData &&(
                          <Paper sx={{ padding: 2 }}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <CountCard
                                  value={livePerformanceData.healthStatus || "NA"}
                                  icon={healthStatusIcon[livePerformanceData.healthStatus as HealthStatus]}
                                  title={"Health Status"}
                                  color={getHealthColor(livePerformanceData.healthStatus || "")}
                                  onClick={() => { }}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>

                                <CountCard
                                  value={livePerformanceData.trackingStatus || "NA"}
                                  icon={trackingStatusIcon[livePerformanceData.trackingStatus as TrackingStatus]}
                                  title={"Tracking Status"}
                                  color={getTrackingColor(livePerformanceData.trackingStatus || "")}
                                  onClick={() => { handleLatLngClick(selectedVehicle?.uniqueId!) }}
                                />

                              </Grid>
                            </Grid>
                          </Paper>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      {vehiclePerformanceData && vehiclePerformanceData.gear_data && (
                        <GearPercentageTable
                          gearDataProp={vehiclePerformanceData.gear_data}
                          liveGear={livePerformanceData?.gear_data}
                          gearLabels={gearLabels}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Grid container spacing={1}>
                    
                    <Grid item xs={12}>
                      {vehiclePerformanceData &&
                        vehiclePerformanceData.ac_usage && (
                          <ACUsageTable
                            acUsage={prepareAcUsageData(vehiclePerformanceData.ac_usage)}
                            liveACUsage={livePerformanceData?.ac_usage}
                          />
                        )}
                    </Grid>
                    <Grid item xs={12}>
                      {vehiclePerformanceData &&
                        vehiclePerformanceData.vehicle_running_mode && (
                          <DriveModeTable
                            driveMode={prepareDriveModeUsageData(vehiclePerformanceData.vehicle_running_mode)}
                            liveDriveMode={
                              livePerformanceData?.vehicle_running_mode
                            }
                          />
                        )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  {vehiclePerformanceData && (
                    <FuelAdblueTable
                      fuelAdblueData={{
                        total_obd_distance:
                          vehiclePerformanceData.total_obd_distance,
                        total_engine_hour: vehiclePerformanceData.total_engine_hour,
                        total_fuel_consumed:
                          vehiclePerformanceData.total_fuel_consumed,
                        mileage: vehiclePerformanceData.mileage,
                        total_adblue_consumed:
                          vehiclePerformanceData.total_adblue_consumed,
                        avg_speed: vehiclePerformanceData.avg_speed,
                      }}
                      liveFuelAdblueData={
                        livePerformanceData
                          ? {
                            total_obd_distance:
                              livePerformanceData.total_obd_distance,
                            total_engine_hour:
                              livePerformanceData.total_engine_hour,
                            total_fuel_consumed:
                              livePerformanceData.total_fuel_consumed,
                            mileage: livePerformanceData.mileage,
                            total_adblue_consumed:
                              livePerformanceData.total_adblue_consumed,
                            avg_speed: livePerformanceData.avg_speed,
                          }
                          : null
                      }
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  {vehiclePerformanceData && livePerformanceData && <SoftwareVersionTable softwareVersions={livePerformanceData ? extractSoftwareData(livePerformanceData) : null} />}
                  <Grid item xs={12}>
                    {vehiclePerformanceData && (
                      <DPFParamsTable
                        dpfHistory={extractDPFParams(vehiclePerformanceData)}
                        liveDpf={
                          livePerformanceData
                            ? extractDPFParams(livePerformanceData)
                            : null
                        }
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  {vehiclePerformanceData && (
                    <IUMPRTable
                      historicData={extractIUMPRData(vehiclePerformanceData)}
                      liveData={
                        livePerformanceData
                          ? extractIUMPRData(livePerformanceData)
                          : null
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default VehiclePerformance;
